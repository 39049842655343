import IsAuthorized, { IsRoleAuthorized } from './isAuthorized';
export function isAuthorizedList(list) {
    if (!list || list.length === 0) {
        return [];
    }
    return list
        .map((item) => {
        const newItem = { ...item };
        if (newItem.children && newItem.children?.length > 0) {
            newItem.children = isAuthorizedList(newItem.children);
        }
        return (newItem.permissionString && IsAuthorized(newItem.permissionString)) ||
            newItem.children?.length > 0 ||
            IsRoleAuthorized(newItem.roles)
            ? newItem
            : null;
    })
        .filter(Boolean);
}
