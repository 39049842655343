import { useAuthContext } from '../auth/useAuthContext';
export default function IsAuthorized(permissionStrings) {
    const { user } = useAuthContext();
    const normalizedPermissionStrings = Array.isArray(permissionStrings)
        ? permissionStrings?.map((permission) => permission?.toLowerCase())
        : [permissionStrings?.toLowerCase()];
    return normalizedPermissionStrings?.every((permission) => user?.permissions?.some((userPermission) => userPermission?.toLowerCase() === permission));
}
export function IsRoleAuthorized(roles) {
    const { user } = useAuthContext();
    return Array.isArray(roles) && roles.includes(user?.role?.code || '');
}
